// Send dropdown selections to Zaraz (only grabs the first select element in the DOM)
(function() {
    
    const selectMenus = document.querySelectorAll('select');

    const callback = function(e) {
        const selectedOption = e.target.options[e.target.selectedIndex];
        zaraz.track('dropdown_select', { selected_option: selectedOption.innerText });
    };

    selectMenus.forEach(selectMenu => {
        selectMenu.addEventListener('change', callback, true);
    });
})();

// Send link clicks to Zaraz
(function() {

    const links = document.querySelectorAll("a");
    const callback = function(e) {
        zaraz.track('link_click', { 
            link_text: e.currentTarget.innerText || "",
            link_url: e.currentTarget.href || "",
            link_class: e.currentTarget.className || "",
            is_related_news: isRelatedNewsLink(e.currentTarget)
        });
    };

    links.forEach(link => {
        link.addEventListener('click', callback, true);
    });

})();

// Send button clicks to Zaraz
(function() {

    const buttons = document.querySelectorAll("button");
    const callback = function(e) {
        console.log({ 
            button_text: e.currentTarget.innerText || "",
            button_id: e.currentTarget.id || "",
            button_class: e.currentTarget.className || ""
        });
        zaraz.track('button_click', { 
            button_text: e.currentTarget.innerText || "",
            button_id: e.currentTarget.id || "",
            button_class: e.currentTarget.className || ""
        });
        
    };

    buttons.forEach(button => {
        button.addEventListener('click', callback, true);
    });

})();

function isRelatedNewsLink(element) {
    var parents = [];
    while (element.parentNode) {
      element = element.parentNode;
      parents.push(element);
    }
    for (var i = 0; i < parents.length; i++) {
      if (parents[i].querySelector('h3') && parents[i].querySelector('h3').innerText === 'Related News') {
        return true;
      }
    }
    return false;
}
  